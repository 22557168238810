<template>
  <div>
    <div class="message is-dark has-margin-bottom-100">
      <div class="message-body has-padding-75 has-text-dark">
        <apart>
          <p>
            Use <strong>FixedVault™</strong> to store provider credentials which
            are accessible to all agents.
          </p>
          <button
            class="button is-dark is-rounded is-outlined"
            @click="addPassword"
          >
            Add credentials
          </button>
        </apart>
      </div>
    </div>

    <b-input
      v-model="searchQuery"
      v-focus
      placeholder="Search credentials..."
      class="has-margin-top-200 has-margin-bottom-100"
    />

    <b-table
      ref="table"
      :data="filteredData"
      :mobile-cards="true"
      :default-sort="[table.sort.field, table.sort.direction]"
      :loading="table.loading"
      :detailed="true"
      :show-detail-icon="false"
      detail-key="_id"
      backend-sorting
      striped
      class="has-vertical-align-middle"
      @sort="onSort"
    >
      <template slot="empty">
        <no-results
          title="No FixedVault™ matches"
          message="There are no credentials matching your query"
          icon="box-open"
        />
      </template>

      <template slot="detail" slot-scope="props">
        <span class="has-white-space-pre-line"
          ><strong>Note</strong>: {{ props.row.note }}</span
        >
      </template>

      <template slot-scope="props">
        <b-table-column
          label="Label"
          field="label"
          width="1"
          class="has-white-space-nowrap"
        >
          {{ props.row.label }}
        </b-table-column>

        <b-table-column
          label="Username"
          field="username"
          width="1"
          class="has-white-space-nowrap"
        >
          <b-tooltip
            label="Copy to clipboard"
            :position="$store.getters['ui/isTouch'] ? 'is-left' : 'is-top'"
            type="is-dark"
          >
            <span
              class="has-cursor-pointer"
              @click="$bus.$emit('copyToClipboard', `${props.row.username}`)"
              >{{ props.row.username }}</span
            >
          </b-tooltip>
        </b-table-column>

        <b-table-column label="Password" field="value">
          <encode-icon
            :password="props.row"
            class="has-margin-right-50"
            @input="$set(props.row, 'viewPassword', $event)"
            @decode="decodePassword(props.row)"
          />
          <b-tooltip
            label="Copy to clipboard"
            :position="$store.getters['ui/isTouch'] ? 'is-left' : 'is-top'"
            type="is-dark"
          >
            <span
              :key="props.row.value"
              class="has-cursor-pointer"
              @click="copyToClipboard(props.row)"
              >{{
                props.row.viewPassword && props.row.state === "decoded"
                  ? props.row.value
                  : "•".repeat(16)
              }}</span
            >
          </b-tooltip>
        </b-table-column>

        <b-table-column width="1" class="has-white-space-nowrap" numeric>
          <span class="has-margin-right-25">
            <b-tooltip
              v-if="props.row.note"
              label="View note"
              position="is-top"
              type="is-info"
            >
              <button
                class="button has-text-info"
                @click="$refs.table.toggleDetails(props.row)"
              >
                <b-icon icon="info" size="is-small" />
              </button>
            </b-tooltip>
          </span>

          <span>
            <b-dropdown position="is-bottom-left">
              <b-button slot="trigger" class="has-text-dark">
                Manage
              </b-button>

              <b-dropdown-item @click="editSecret(props.row)"
                >Edit</b-dropdown-item
              >

              <b-dropdown-item
                class="has-text-danger"
                @click="removePassword(props.row)"
                >Delete</b-dropdown-item
              >
            </b-dropdown>
          </span>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import { collection } from "@firebase/firestore";
export default {
  name: "FixedVaultTable",
  components: {
    "encode-icon": () => import("@shared/vault/_encodeIcon.vue")
  },
  mixins: [require("@mixins/table-with-filtering-and-sorting").default],
  data() {
    return {
      searchQuery: "",
      table: {
        limit: 1000,
        sort: { field: "labelLowercase", direction: "asc" }
      }
    };
  },
  computed: {
    filteredData() {
      return this.$_.filter(
        this.table.data,
        password =>
          this.$_.includes(
            this.$_.toUpper(password.label),
            this.$_.toUpper(this.searchQuery)
          ) ||
          this.$_.includes(
            this.$_.toUpper(password.username),
            this.$_.toUpper(this.searchQuery)
          )
      );
    }
  },
  methods: {
    getFirestoreRef() {
      try {
        let ref = collection(this.$firestore, "vault");
        return this.buildFirestoreRef(ref);
      } catch (e) {
        console.error(e);
      }
    },
    addPassword() {
      this.$store.dispatch("vault/openVaultPasswordModal").then(() => {
        this.getData();
      });
    },
    decodePassword(password) {
      this.$set(password, "state", "processing");
      return this.$store
        .dispatch("vault/decodeValue", {
          value: password.value,
          passwordId: password._id
        })
        .then(result => {
          this.$set(password, "value", result.value);
          this.$set(password, "state", "decoded");
        })
        .catch(error => {
          this.$set(password, "state", "error");
          this.$toast.open({
            message: `${error.message}`,
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        });
    },
    editSecret(password) {
      this.$store
        .dispatch("vault/openVaultPasswordModal", {
          password: password
        })
        .then(event => {
          if (event === "success") this.getData();
        });
    },
    removePassword({ _id }) {
      this.processing = true;
      this.$store
        .dispatch("vault/removePassword", {
          passwordId: _id
        })
        .then(result => {
          this.$toast.open({
            message: result.message,
            position: "is-bottom",
            type: "is-success",
            queue: false
          });
          this.getData();
        })
        .catch(error => {
          this.$toast.open({
            message: `${error.message}`,
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        })
        .finally(() => {
          this.processing = false;
        });
    },
    async copyToClipboard(password) {
      if (password.state !== "decoded") await this.decodePassword(password);
      this.$set(password, "viewPassword", true);
      this.$bus.$emit("copyToClipboard", `${password.value}`);
    }
  }
};
</script>
